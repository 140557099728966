import React from "react";
import { SearchResult } from "./SearchResult";
import { useBasket } from '../hooks/useBasket';

import {
    ErrorBoundary,
    Facet,
    SearchProvider,
    WithSearch,
    SearchBox,
    PagingInfo,
    ResultsPerPage,
    Paging,
    Sorting
} from "@elastic/react-search-ui";

import { Layout } from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";

import buildRequest from "./buildRequest";
import runRequest from "./runRequest";
import applyDisjunctiveFaceting from "./applyDisjunctiveFaceting";
import buildState from "./buildState";
import { MealDetails } from "./MealDetails";


export const MealSearch = ({ meal }) => {
    const { addFood } = useBasket();
    const config = {
        debug: process.env.NODE_ENV !== "production",
        hasA11yNotifications: true,
        onResultClick: () => { },
        onAutocompleteResultClick: () => { },
        onAutocomplete: () => { },
        onSearch: async state => {
            const { resultsPerPage } = state;
            const requestBody = buildRequest(state);
            // Note that this could be optimized by running all of these requests
            // at the same time. Kept simple here for clarity.
            const responseJson = await runRequest(requestBody);
            const responseJsonWithDisjunctiveFacetCounts = await applyDisjunctiveFaceting(
                responseJson,
                state,
                ["dataType", "foodClass", "searchCategory", "searchBrand", "searchPortion"]
            );
            return buildState(responseJsonWithDisjunctiveFacetCounts, resultsPerPage);
        }
    };

    return (
        <>
            <MealDetails meal={meal} />
            <SearchProvider config={config}>
                <WithSearch mapContextToProps={({ wasSearched, results }) => ({ wasSearched, results })}>
                    {({ wasSearched, results }) => (
                        <ErrorBoundary>
                            <Layout
                                header={
                                    <SearchBox />
                                }
                                sideContent={
                                    <div>
                                        {wasSearched && (
                                            <Sorting
                                                label={"Sort by"}
                                                sortOptions={[
                                                    {
                                                        name: "Relevance",
                                                        value: "",
                                                        direction: ""
                                                    },
                                                    {
                                                        name: "Title",
                                                        value: "title",
                                                        direction: "asc"
                                                    }
                                                ]}
                                            />
                                        )}
                                        <Facet
                                            field="dataType"
                                            label="Datasets"
                                            filterType="any"
                                        />
                                        <Facet
                                            field="not_further_specified"
                                            label="NFS Food?"
                                            filterType="any"
                                        />
                                        <Facet
                                            field="searchCategory"
                                            label="Food Category"
                                            filterType="any"
                                            isFilterable={true}
                                        />
                                        <Facet
                                            field="searchBrand"
                                            label="Brand"
                                            filterType="any"
                                            isFilterable={true}
                                        />
                                        <Facet
                                            field="searchPortion"
                                            label="Food Portion"
                                            filterType="any"
                                            isFilterable={true}
                                        />
                                        <Facet
                                            field="foodClass"
                                            label="Food Types"
                                            filterType="any"
                                            isFilterable={true}
                                        />

                                    </div>
                                }
                                bodyContent={
                                    <ErrorBoundary>
                                        {results.map(r => (
                                            <SearchResult key={r.id.raw} result={r} addToBasket={addFood} />
                                        ))}
                                    </ErrorBoundary>
                                }
                                bodyHeader={
                                    <React.Fragment>
                                        {wasSearched && <PagingInfo />}
                                        {wasSearched && <ResultsPerPage />}
                                    </React.Fragment>
                                }
                                bodyFooter={<Paging />}
                            />
                        </ErrorBoundary>
                    )}
                </WithSearch>
            </SearchProvider>

        </>
    );

}