import React, { createContext, useReducer } from 'react';
import { BasketReducer } from './BasketReducer';

export const BasketContext = createContext();

const storage = localStorage.getItem('basket') ? JSON.parse(localStorage.getItem('basket')) : [];
const initialState = { foods: storage, saved: false };

const BasketContextProvider = ({ onSave, children }) => {
    const [state, dispatch] = useReducer(BasketReducer, initialState);

    const addFood = payload => {
        dispatch({ type: 'ADD_FOOD', payload })
    }
    const removeFood = payload => {
        dispatch({ type: 'REMOVE_FOOD', payload })
    }
    const clearBasket = _ => {
        dispatch({ type: 'CLEAR_BASKET' })
    }
    const saveBasket = async _ => {
        if (onSave) {
            if (await onSave(state.foods))
                dispatch({ type: 'SAVE_BASKET' });
        }

    }

    const values = {
        addFood,
        removeFood,
        clearBasket,
        saveBasket,
        ...state
    }

    return (
        <BasketContext.Provider value={values}>
            {children}
        </BasketContext.Provider>
    )
}

export default BasketContextProvider;