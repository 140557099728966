import { useContext } from 'react';
import { BasketContext } from '../context/BasketContext';

export const useBasket = () => {
   
    const ctx = useContext(BasketContext)

    return {
        ...ctx
    }
}