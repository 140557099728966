import React from "react";
import {
    EuiButton, EuiButtonEmpty, EuiFlexGroup, EuiFlexItem,
    EuiFlyout, EuiFlyoutHeader, EuiFlyoutBody, EuiFlyoutFooter, EuiTitle, EuiInMemoryTable, EuiButtonIcon
} from "@elastic/eui";
import { useBasket } from '../hooks/useBasket';


export const FoodBasket = ({ visible, setVisible }) => {
    const { removeFood, clearBasket, saveBasket, foods } = useBasket();
    const closeBasket = () => setVisible(false);

    const columns = [
        {
            field: 'description',
            name: 'Description',
            truncateText: true
        },
        {
            field: 'portionQty',
            name: 'Quantity',
        },
        {
            field: 'portionUnit',
            name: 'Unit',
        },
        {
            name: 'Actions',
            actions: [{
                render: item => (
                    <EuiButtonIcon iconType="trash" aria-label="Delete" color="danger" onClick={() => removeFood(item)} />
                )
            }]
        }
    ];

    if (visible) {
        return (
            <EuiFlyout
                size="m"
                onClose={_ => closeBasket()}
            >
                <EuiFlyoutHeader hasBorder>
                    <EuiTitle size="m">
                        <h2>Meal Components</h2>
                    </EuiTitle>
                </EuiFlyoutHeader>
                <EuiFlyoutBody>
                    <EuiInMemoryTable
                        items={foods}
                        columns={columns}
                        pagination={true}
                        compressed={true}
                    />
                </EuiFlyoutBody>
                <EuiFlyoutFooter>
                    <EuiFlexGroup justifyContent="spaceBetween">
                        <EuiFlexItem grow={false}>
                            <EuiButtonEmpty
                                iconType="cross"
                                onClick={closeBasket}
                                flush="left"
                            >
                                Close
                            </EuiButtonEmpty>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiButton color="danger" onClick={() => { clearBasket(); }} >
                                Clear
                            </EuiButton>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiButton onClick={() => { saveBasket(); }} fill>
                                Save
                            </EuiButton>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlyoutFooter>
            </EuiFlyout >
        );
    }

    return <></>;
};