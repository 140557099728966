import React, { useState } from 'react';
import {
    EuiButton,
    EuiFieldNumber,
    EuiFlexGroup,
    EuiFlexItem,
    EuiForm,
    EuiFormRow,
    EuiSuperSelect,
} from '@elastic/eui';

export const PortionForm = ({ food, onSave }) => {
    let portions = [{ value: 'g', inputDisplay: 'g', gramWeight: 1, sequenceNumber: 0 }];

    if (food.servingSize) {
        portions.push({ value: 'serving', inputDisplay: `serving (${food.servingSize.raw} ${food.servingSizeUnit.raw})`, gramWeight: 1, sequenceNumber: 1 })
    }

    if (food.foodPortions) {
        for (const { id, gramWeight, portionDescription, modifier } of food.foodPortions.raw) {
            portions.push({ value: id, inputDisplay: portionDescription ?? modifier, gramWeight });
        }
    }

    portions.sort((a, b) => a.sequenceNumber - b.sequenceNumber)

    const [qty, setQty] = useState(0);
    const setPortion = e => {
        const sanitizedValue = parseInt(e.target.value, 10);
        setQty(isNaN(sanitizedValue) ? 0 : sanitizedValue);
    };
    const [units, setUnits] = useState('g');

    return (
        <EuiForm>
            <EuiFlexGroup>
                <EuiFlexItem grow={false} style={{ width: 100 }}>
                    <EuiFormRow label="Portion Quantity">
                        <EuiFieldNumber placeholder={0} onChange={setPortion} value={qty} />
                    </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiFormRow label="Portion Units">
                        <EuiSuperSelect options={portions} onChange={setUnits} valueOfSelected={units} />
                    </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiFormRow hasEmptyLabelSpace>
                        <EuiButton onClick={
                            () => {
                                const { inputDisplay, gramWeight } = portions.find(p => p.value === units);
                                onSave({
                                    portionQty: qty,
                                    portionUnit: `${inputDisplay} (${units})`,
                                    portionWeight: gramWeight
                                });
                            }}>
                            Save
                        </EuiButton>
                    </EuiFormRow>
                </EuiFlexItem>
            </EuiFlexGroup>
        </EuiForm>
    );
}