import React from 'react';

import { EuiInMemoryTable } from '@elastic/eui';

export const NutritionTable = ({nutrients}) => {
    const columns = [
        {
            field: 'name',
            name: 'Nutrient',
            sortable: true
        },
        {
            field: 'amount',
            name: 'Amount',
            sortable: true
        },
        {
            field: 'unit',
            name: 'Unit',
            sortable: true
        }
    ];



    return (<EuiInMemoryTable
        tableCaption="Nutrition Info"
        items={nutrients.filter(n => n.amount > 0)}
        columns={columns}
        pagination={true}
        compressed={true}
    />);
}