import { EuiButton, EuiButtonIcon, EuiIcon, EuiPopover, EuiDescriptionList, EuiCard, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiTextArea, EuiInMemoryTable, EuiSpacer } from "@elastic/eui";
import React, { useState } from "react";
import { useBasket } from '../hooks/useBasket';



export const MealDetails = ({ meal }) => {
    const { addFood, removeFood, clearBasket, saveBasket, foods } = useBasket();
    const [isOpen, setIsOpen] = useState(false)
    const [note, setNote] = useState('')

    const columns = [
        {
            field: 'description',
            name: 'Description',
            truncateText: true
        },
        {
            field: 'portionQty',
            name: 'Quantity',
        },
        {
            field: 'portionUnit',
            name: 'Unit',
        },
        {
            name: 'Actions',
            actions: [{
                render: item => (
                    <EuiButtonIcon iconType="trash" aria-label="Delete" color="danger" onClick={() => removeFood(item)} />
                )
            }]
        }
    ];

    const {
        name,
        timestamp,
        foodDesc,
        foodQty,
        drinkDesc,
        drinkQty,
        mealPurchase
    } = meal;



    const popoverButton =
        <EuiButton iconType="arrowDown"
            iconSide="right"
            color="success"
            onClick={() => setIsOpen(open => !open)}>
            Add Note
        </EuiButton>

    const notesPopover =
        <EuiPopover button={popoverButton} isOpen={isOpen} closePopover={() => setIsOpen(false)}>
            <div style={{ width: 500 }}>
                <EuiForm title="Add a Note" component="form">
                    <EuiFormRow label="Add a Note" fullWidth>
                        <EuiTextArea fullWidth value={note} onChange={(e) => setNote(e.target.value)} />
                    </EuiFormRow>
                    <EuiFlexGroup>
                        <EuiFlexItem>
                            <EuiButton onClick={() => {

                                setNote('')
                                setIsOpen(false)
                            }}>
                                Cancel
                            </EuiButton>
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiButton fill onClick={() => {
                                addFood({
                                    id: '9999999',
                                    description: note,
                                    portionQty: 0,
                                    portionWeight: 0,
                                    portionUnit: 'Placeholder/Note'
                                });
                                setNote('')
                                setIsOpen(false)
                            }}>
                                Save Note
                            </EuiButton>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiForm>
            </div>
        </EuiPopover >

    return (
        <EuiCard title={`${name} (${timestamp.toLocaleString()})`}
            titleSize="s" icon={<EuiIcon size="xl" type={'reportingApp'} />}
            footer={<EuiFlexGroup>
                <EuiFlexItem>
                    <EuiFlexGroup>
                        {/* <EuiFlexItem>
                            <EuiButton iconSide="left" iconType="arrowLeft">Previous Meal</EuiButton>
                        </EuiFlexItem>
                        <EuiSpacer />
                        <EuiFlexItem>
                            <EuiButton iconSide="right" iconType="arrowRight">Next Meal</EuiButton>
                        </EuiFlexItem> */}
                    </EuiFlexGroup>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiFlexGroup>
                        <EuiFlexItem grow={true}>
                            {notesPopover}
                        </EuiFlexItem>
                        <EuiSpacer/>
                        <EuiFlexItem>
                            <EuiButton iconSide="right" iconType="cross" color="danger" onClick={_ => clearBasket()} >Clear Basket</EuiButton>
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiButton iconSide="right" iconType="save" fill onClick={_ => saveBasket()}>Save Basket</EuiButton>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
            </EuiFlexGroup>}
        >
            <EuiFlexGroup>
                <EuiFlexItem align="left">
                    <EuiDescriptionList align="left" listItems={[
                        {
                            title: "Food Description",
                            description: foodDesc,
                        },
                        {
                            title: "Food Quantities",
                            description: foodQty,
                        },
                        {
                            title: "Drink Description",
                            description: drinkDesc,
                        },
                        {
                            title: "Drink Quantities",
                            description: drinkQty,
                        },
                        {
                            title: "Purchase Details",
                            description: mealPurchase,
                        }]} />
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiInMemoryTable items={foods} columns={columns} pagination={true} compressed={true} />
                </EuiFlexItem>
            </EuiFlexGroup>
        </EuiCard>
    )
}