import React, { useEffect, useState, useCallback } from "react";
import '@elastic/eui/dist/eui_theme_light.css';
import { EuiButton, EuiProvider, EuiPageTemplate, EuiEmptyPrompt, EuiLoadingSpinner, EuiAvatar, EuiSpacer} from '@elastic/eui';

import { gapi } from 'gapi-script';
import { MealSearch } from "./components/MealSearch";
import { SurveyMeals } from './components/SurveyMeals';
import { FoodBasket } from "./components/FoodBasket";
import BasketContextProvider from "./context/BasketContext";

// Client ID and API key from the Developer Console
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const API_KEY = process.env.REACT_APP_API_KEY;

// Array of API discovery doc URLs for APIs
const DISCOVERY_DOCS = ['https://sheets.googleapis.com/$discovery/rest?version=v4'];

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
const SCOPES = 'openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/drive.file';

const ENGLISH_SHEET_ID = '1HeUDzA44H9R0jjQ_Q0KbDe1_awa2iubO0L8SYq8mW1M';
const SPANISH_SHEET_ID = '1X3NahsALA1J-tHv4ObYsQnf-MJYz10jn0x2bq26moqo';
const RESULTS_SHEET_ID = '1aVJ3V_cLLzp0z0Dag5ca84e8TufEF9F2bDuqG7u5lZ0';

export default function App() {
  const [signedIn, setSignedIn] = useState(false);
  // const [userProfile, setUserProfile] = useState(null);
  const [gapiLoaded, setGapiLoaded] = useState(false);
  const [selectedTab, setSelectedTab] = useState('english-meals');
  const [englishMeals, setEnglishMeals] = useState([]);
  const [spanishMeals, setSpanishMeals] = useState([]);
  const [activeMeal, setActiveMeal] = useState();
  const [completedResponses, setCompletedResponses] = useState(new Set());
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 12, pageSizeOptions: [12, 24, 36, 48] })
  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    const fetchResponses = async (spreadsheetId) => {
      console.log("Fetching responses");
      const response = await gapi.client.sheets.spreadsheets.values.batchGet({
        spreadsheetId,
        ranges: ['Responses'],
        dateTimeRenderOption: 'SERIAL_NUMBER',
      });
      let values = response.result.valueRanges[0].values;
      values.shift();
      const result = new Set(values.map(r => `${r[0]}-${r[1]}`))
      console.log("Finished fetching responses:", result.size)
      setCompletedResponses(result);
    }
    if (signedIn && (!completedResponses || completedResponses.size === 0)) {
      fetchResponses(RESULTS_SHEET_ID);
    }
  }, [signedIn, gapiLoaded, completedResponses])

  const fetchMeals = useCallback(async (spreadsheetId, saveResults) => {
    console.log("Fetching meals from", spreadsheetId);
    const response = await gapi.client.sheets.spreadsheets.values.batchGet({
      spreadsheetId,
      ranges: ['Timestamps', 'Meals'],
      dateTimeRenderOption: 'SERIAL_NUMBER',
    });
    const timestamps = response.result.valueRanges[0].values.map(x => x[0]);
    const responseMeals = response.result.valueRanges[1].values;

    let results = [];

    for (let i = 1; i < timestamps.length; i++) {
      const responseId = `${spreadsheetId}-${i + 1}`;
      const timestamp = new Date(timestamps[i]);
      results = results.concat([{
        name: 'Breakfast',
        foodDesc: responseMeals[i][0],
        foodQty: responseMeals[i][1],
        drinkDesc: responseMeals[i][2],
        drinkQty: responseMeals[i][3],
        mealPurchase: responseMeals[i][4],
        responseId,
        timestamp,
        mealId: `${responseId}-Breakfast`
      },
      {

        name: 'Snack 1',
        foodDesc: responseMeals[i][5],
        foodQty: responseMeals[i][6],
        drinkDesc: responseMeals[i][7],
        drinkQty: responseMeals[i][8],
        mealPurchase: responseMeals[i][9],
        responseId,
        timestamp,
        mealId: `${responseId}-Snack 1`
      },
      {

        name: 'Lunch',
        foodDesc: responseMeals[i][10],
        foodQty: responseMeals[i][11],
        drinkDesc: responseMeals[i][12],
        drinkQty: responseMeals[i][13],
        mealPurchase: responseMeals[i][14],
        responseId,
        timestamp,
        mealId: `${responseId}-Lunch`
      },
      {
        name: 'Snack 2',
        foodDesc: responseMeals[i][15],
        foodQty: responseMeals[i][16],
        drinkDesc: responseMeals[i][17],
        drinkQty: responseMeals[i][18],
        mealPurchase: responseMeals[i][19],
        responseId,
        timestamp,
        mealId: `${responseId}-Snack 2`
      },
      {
        name: 'Dinner',
        foodDesc: responseMeals[i][20],
        foodQty: responseMeals[i][21],
        drinkDesc: responseMeals[i][22],
        drinkQty: responseMeals[i][23],
        mealPurchase: responseMeals[i][24],
        responseId,
        timestamp,
        mealId: `${responseId}-Dinner`
      },
      {
        name: 'Snack 3',
        foodDesc: responseMeals[i][25],
        foodQty: responseMeals[i][26],
        drinkDesc: responseMeals[i][27],
        drinkQty: responseMeals[i][28],
        mealPurchase: responseMeals[i][29],
        responseId,
        timestamp,
        mealId: `${responseId}-Snack 3`
      },
      ]);
    }
    // setFetching(false);
    console.log("Finished fetching meals:", results.length)
    saveResults(results);
  }, [])

  useEffect(() => {
    if (signedIn) {
      if (!englishMeals || englishMeals.length === 0) {
        fetchMeals(ENGLISH_SHEET_ID, setEnglishMeals);
      }
    }
  }, [gapiLoaded, signedIn, englishMeals, fetchMeals]);

  useEffect(() => {
    if (signedIn) {
      if (!spanishMeals || spanishMeals.length === 0) {
        fetchMeals(SPANISH_SHEET_ID, setSpanishMeals);
      }
    }
  }, [gapiLoaded, signedIn, spanishMeals, fetchMeals]);



  


  const handleSignOutClick = (_evt) => {
    if (gapiLoaded && gapi.auth2.getAuthInstance().isSignedIn.get()) {
      gapi.auth2.getAuthInstance().signOut();
    }
  };

  const handleSignInClick = (_evt) => {
    if (gapiLoaded && !gapi.auth2.getAuthInstance().isSignedIn.get()) {
      gapi.auth2.getAuthInstance().signIn();
    }
  };


  const initClient = useCallback(() => {
    const updateSigninStatus = (isSignedIn) => {
      console.log("Sign-in status changed:", isSignedIn);
      setSignedIn(isSignedIn);
    };
    const updateSignedInUser = (currentUser) => {
      console.log("Current user changed:", currentUser.isSignedIn());
      if (currentUser.isSignedIn()) {
        setCurrentUser({
          name: currentUser.getBasicProfile().getName(),
          imageUrl: currentUser.getBasicProfile().getImageUrl(),
        });
      } else {
        setCurrentUser(null);
      }
    }
    console.log("Initializing Google API client");
    gapi.client
      .init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      })
      .then(
        function () {
          const auth = gapi.auth2.getAuthInstance();
          setGapiLoaded(true);
          const signedIn = auth.isSignedIn.get();

          // Listen for sign-in state changes.
          auth.isSignedIn.listen(updateSigninStatus);
          auth.currentUser.listen(updateSignedInUser);

          updateSignedInUser(auth.currentUser.get())
          updateSigninStatus(signedIn)


          // Handle the initial sign-in state.
          // if (!signedIn) {
          //   gapi.auth2.getAuthInstance().signIn();
          // }

        },
        function (error) { }
      );
  }, []);






  useEffect(() => {
    if (!gapiLoaded) {
      gapi.load('client:auth2', initClient);
    }
  }, [gapiLoaded, initClient]);

  const [basketVisible, setBasketVisible] = useState(false);

  const saveBasket = async basket => {
    let success = false;
    console.log('Saving:', basket);
    const values = basket.map(food =>
      [activeMeal.responseId, activeMeal.name, food.id, food.description, food.portionUnit, food.portionWeight, food.portionQty]
    );
    try {
      await gapi.client.sheets.spreadsheets.values.append({
        spreadsheetId: RESULTS_SHEET_ID,
        range: 'Responses',
        valueInputOption: 'RAW',
        resource: { values }
      });
      console.log(`Saved ${activeMeal.name} for ${activeMeal.responseId}`);
      success = true;
    } catch (e) {
      console.error(e)
      alert(`Failed to save, try logging out and back in`)
    }

    return success;
  }
  const onTableChange = (change) => {
    const { page: { index, size } } = change
    setPagination({ ...pagination, pageIndex: index, pageSize: size })
  }



  const tabContent = tabId => {
    switch (tabId) {
      case 'english-meals':
        return (<SurveyMeals pagination={pagination} onTableChange={onTableChange} meals={englishMeals.map(m => ({ ...m, complete: completedResponses.has(m.mealId) }))} editMeal={(meal) => {
          setActiveMeal(meal);
          setSelectedTab('meal-details');
        }} />);
      case 'spanish-meals':
        return (<SurveyMeals pagination={pagination} onTableChange={onTableChange} meals={spanishMeals.map(m => ({ ...m, complete: completedResponses.has(m.mealId) }))} editMeal={(meal) => {
          setActiveMeal(meal);
          setSelectedTab('meal-details');
        }} />);
      case 'meal-details':
        return (<MealSearch meal={activeMeal} />);
      default:
        return (<SurveyMeals pagination={pagination} onTableChange={onTableChange} meals={englishMeals.map(m => ({ ...m, complete: completedResponses.has(m.mealId) }))} editMeal={(meal) => {
          setActiveMeal(meal);
          setSelectedTab('meal-details');
        }} />);
    }
  }
  return (
    <BasketContextProvider onSave={saveBasket}>
      <EuiProvider colorMode="light">
        {!gapiLoaded ?
          <EuiEmptyPrompt title={<h4>Loading...</h4>} icon={<EuiLoadingSpinner size="xl" />} /> :
          !signedIn ?
            <EuiEmptyPrompt titleSize="xs"
              title={<h4>Not logged in</h4>}
              iconType="user"
              actions={
                <EuiButton
                  onClick={handleSignInClick}
                  size="s" color="primary" fill>
                  Login
                </EuiButton>}
              body={<p>Please log in with your Google account to continue.</p>} /> :
            <EuiPageTemplate pageHeader={{
              rightSideItems: [
                currentUser !== null ? <EuiAvatar size="l" name={currentUser.name} imageUrl={currentUser.imageUrl} />: <EuiSpacer></EuiSpacer>,
                // <EuiButton onClick={() => setBasketVisible(true)} fill>Show Basket</EuiButton>,
                signedIn ?
                  <EuiButton onClick={handleSignOutClick}>Logout</EuiButton> :
                  <EuiButton onClick={handleSignInClick}>Login</EuiButton>,
              ],
              tabs: [
                {
                  label: 'English',
                  onClick: () => setSelectedTab('english-meals'),
                  isSelected: 'english-meals' === selectedTab,
                },
                {
                  label: 'Spanish',
                  onClick: () => setSelectedTab('spanish-meals'),
                  isSelected: 'spanish-meals' === selectedTab,
                },
                {
                  label: 'Meal Entry',
                  onClick: () => setSelectedTab('meal-details'),
                  isSelected: 'meal-details' === selectedTab,
                  disabled: activeMeal === undefined,
                }
              ]
            }}>
              {tabContent(selectedTab)}
              <FoodBasket setVisible={setBasketVisible} visible={basketVisible} />
            </EuiPageTemplate>
        }
      </EuiProvider >
    </BasketContextProvider>
  );
}
