const Storage = (foods) => {
    localStorage.setItem('basket', JSON.stringify(foods.length > 0 ? foods : []))
}

export const BasketReducer = (state, action) => {
    let next = { ...state };
    switch (action.type) {
        case 'ADD_FOOD':
            // if (!state.foods.find(food => food.id === action.payload.id)) {
            //     next.foods.push({
            //         ...action.payload
            //     });
            // }
            next.foods.push({
                ...action.payload
            });
            Storage(next.foods);
            return next;
        case 'REMOVE_FOOD':
            next.foods = next.foods.filter(food => food.id !== action.payload.id);
            Storage(next.foods);
            return next;
        case 'CLEAR_BASKET':
            next.foods = [];
            Storage(next.foods);
            return next;
        case 'SAVE_BASKET':
            next = {
                ...next,
                saved: true,
                foods: []
            };
            Storage(next.foods);
            return next;
        default:
            return state;
    }
}