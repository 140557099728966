import React, { } from 'react';

import { EuiButton, EuiInMemoryTable, EuiIcon, formatDate } from '@elastic/eui';

export const SurveyMeals = ({ meals, editMeal, onTableChange, pagination }) => {
    const columns = [
        {
            field: 'complete',
            name: 'Saved',
            dataType: 'boolean',
            render: (complete) => complete ? <EuiIcon type="check" /> : <EuiIcon type="cross" />,
            sortable: true,
            width: '5%',
            align: 'center'
        },
        {
            field: 'timestamp',
            name: 'Timestamp',
            dataType: 'date',
            render: (date) => formatDate(date, "MM/DD/YY HH:mm:ss"),
            sortable: true,
            truncateText: true,
        },
        {
            field: 'name',
            name: 'Meal',
            sortable: true,
            truncateText: true,

        },
        {
            field: 'foodDesc',
            name: 'Food Description',
            truncateText: true,

        },
        {
            field: 'foodQty',
            name: 'Food Quantity',
            truncateText: true,

        },
        {
            field: 'drinkDesc',
            name: 'Drink Description',
            truncateText: true,

        },
        {
            field: 'drinkQty',
            name: 'Drink Quantity',
            truncateText: true,

        },
        {
            field: 'mealPurchase',
            name: 'Purchase Details',
            truncateText: true,

        },
        {
            name: 'Actions',
            actions: [{
                render: (item) => {
                    return (
                        <EuiButton iconType="documentEdit" size="s" onClick={() => editMeal(item)}>Edit Meal</EuiButton>
                    )
                }
            }
            ]
        }
    ];

    const sorting = {
        sort: {
            field: 'timestamp',
            direction: 'desc',
        },
    };

    return (

        <EuiInMemoryTable
            tableCaption="Survey Meals"
            items={meals}
            columns={columns}
            pagination={pagination}
            sorting={sorting}
            hasActions={true}
            onTableChange={onTableChange}
        />


    );
}
