import React, { useState } from "react";
import { NutritionTable } from "./NutritionTable";
import { PortionForm } from "./PortionForm";

import {
    EuiAccordion,
    EuiButton,
    EuiCard,
    EuiLink,
    EuiPopover,
    EuiFlexGroup,
    EuiFlexItem,
    EuiDescriptionList,
    EuiSpacer,
} from '@elastic/eui';

export const SearchResult = ({ result, addToBasket }) => {
    const [isOpen, setOpen] = useState(false);
    const togglePopover = () => setOpen(o => !o);
    const closePopover = () => setOpen(false);

    const popoverButton = (
        <EuiButton
            iconSide="right"
            fill
            iconType="arrowDown"
            onClick={togglePopover}>
            Add to Basket
        </EuiButton>);

    const addWithPortionInfo = (portion) => {
        const food = {
            id: result.id.raw,
            description: result.description.raw,
            ...portion
        };
        addToBasket(food);
    }

    const fields = [
        {
            title: 'Dataset',
            description: result.dataType.raw
        },
        {
            title: 'Category',
            description: result.brandedFoodCategory ? result.brandedFoodCategory.raw :
                result.wweiaFoodCategory ? result.wweiaFoodCategory.raw.wweiaFoodCategoryDescription :
                    result.foodCategory ? result.foodCategory.raw.description : 'N/A'
        },
        {
            title: 'Brand',
            description: result.brandName ? result.brandName.raw : 'N/A'
        },
    ]

    const nutrients = result.foodNutrients ? result.foodNutrients.raw.map(n =>
    ({
        name: n.nutrient.name,
        amount: n.amount,
        unit: n.nutrient.unitName,
        derivation: n.foodNutrientDerivation ? n.foodNutrientDerivation.description : "Not specified",
        rank: n.nutrient.rank
    })).sort((a, b) => a.rank - b.rank) : [];

    return (
        <>
            <EuiCard
                key={result.id.raw}
                title={<h5>{`${result.description.raw} (${result.id.raw})`}</h5>}
                footer={
                    <EuiLink href={`https://fdc.nal.usda.gov/fdc-app.html#/food-details/${result.id.raw}/nutrients`} external target="_blank">
                        View item on Food Data Central
                    </EuiLink>
                }
            >
                <EuiFlexGroup>
                    <EuiFlexItem>
                        <EuiDescriptionList type="column" listItems={fields} compressed={true}></EuiDescriptionList>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiPopover button={popoverButton} isOpen={isOpen} closePopover={closePopover}>
                            <div style={{ width: 500 }}>
                                <PortionForm food={result} onSave={addWithPortionInfo} />
                            </div>
                        </EuiPopover>
                    </EuiFlexItem>
                </EuiFlexGroup >
                <EuiFlexGroup>
                    <EuiAccordion id="nutrient-info" buttonContent="Nutrition Info">
                        <NutritionTable nutrients={nutrients} />
                    </EuiAccordion>
                </EuiFlexGroup>
            </EuiCard>
            <EuiSpacer />
        </>
    );
}