function getValueFacet(aggregations, fieldName) {
  if (
    aggregations &&
    aggregations[fieldName] &&
    aggregations[fieldName].buckets &&
    aggregations[fieldName].buckets.length > 0
  ) {
    return [
      {
        field: fieldName,
        type: "value",
        data: aggregations[fieldName].buckets.map(bucket => ({
          // Boolean values and date values require using `key_as_string`
          value: bucket.key_as_string || bucket.key,
          count: bucket.doc_count
        }))
      }
    ];
  }
}

// function getRangeFacet(aggregations, fieldName) {
//   if (
//     aggregations &&
//     aggregations[fieldName] &&
//     aggregations[fieldName].buckets &&
//     aggregations[fieldName].buckets.length > 0
//   ) {
//     return [
//       {
//         field: fieldName,
//         type: "range",
//         data: aggregations[fieldName].buckets.map(bucket => ({
//           // Boolean values and date values require using `key_as_string`
//           value: {
//             to: bucket.to,
//             from: bucket.from,
//             name: bucket.key
//           },
//           count: bucket.doc_count
//         }))
//       }
//     ];
//   }
// }

export default function buildStateFacets(aggregations) {
  const dataType = getValueFacet(aggregations, "dataType");
  const foodClass = getValueFacet(aggregations, "foodClass");
  const searchCategory = getValueFacet(aggregations, "searchCategory");
  const searchBrand = getValueFacet(aggregations, "searchBrand");
  const searchPortion = getValueFacet(aggregations, "searchPortion");
  const not_further_specified = getValueFacet(aggregations, "not_further_specified");

  const facets = {
    ...(dataType && { dataType }),
    ...(not_further_specified && { not_further_specified}),
    ...(foodClass && { foodClass }),
    ...(searchCategory && { searchCategory }),
    ...(searchBrand && { searchBrand }),
    ...(searchPortion && { searchPortion }),

  };

  if (Object.keys(facets).length > 0) {
    return facets;
  }
}
